const columns = [
    {label: "编号", prop: "rechargeRecordNo", width: '100'},
    {label: "运营商", prop: "operatorName"},
    {label: "供应商", prop: "supplierName"},
    {label: "服务类型", prop: "productName", width: 120},
    {label: "应付总金额", prop: "rechargeTotalPrice", width: '100'},
    {label: "支付方式", prop: "payTypeName", width: '120'},
    {label: "支付流水号", prop: "transactionId", width: '160'},
    {label: "支付金额", prop: "payPrice", width: '120'},
    {label: "提交时间", prop: "createTime", width: '150'},
    {label: "提交人", prop: "auditName", width: '120'},
    {label: "状态", prop: "payStatus", width: '120', isPayStatus: true}
];
export default {
    columns
}
