<style lang="scss" scoped>
</style>
<template>

    <div>
        <recharge-products-table :columns="data.columns" :dataList="list" :show-buttons="true"
                                 :is-slot="true">
            <template v-slot:desc="slotDesc">
                <p v-if="slotDesc.col.isPayStatus">
                    <span style="display: inline-block;padding: 2px 5px;border-radius: 2px;background: #F4F4F5" :style="slotDesc.row[slotDesc.col.prop] | formatDesc(auditStatusList, 'value', 'style')" :class="slotDesc.row[slotDesc.col.colColor]">
                    {{slotDesc.row[slotDesc.col.prop] | formatDesc(auditStatusList)}}
                    </span>
                </p>
                <p v-else>
                    {{slotDesc.row[slotDesc.col.prop]}}
                </p>
            </template>
            <template v-slot:buttons="slotRow">
                <el-button type="text" @click="handleSeeBtn(slotRow.row)">查看</el-button>
                <el-button type="text" @click="handleAuditBtn(slotRow.row)" v-if="slotRow.row.payStatus === 0 && serviceType === '1'">审核</el-button>
                <el-button type="text" @click="handleEditBtn(slotRow.row, 0)" v-if="slotRow.row.payStatus === 0 || slotRow.row.payStatus === 2 && serviceType === '0'">修改</el-button>
                <el-button type="text" @click="handleCancelBtn(slotRow.row)" v-if="slotRow.row.payStatus === 0 && serviceType === '0'">取消</el-button>
                <el-button type="text" @click="handleReSubBtn(slotRow.row, 1)" v-if="slotRow.row.payStatus === 2 && serviceType === '0'">重新提交</el-button>
                <el-button type="text" @click="handleLogBtn(slotRow.row)">日志</el-button>
<!--                <el-button type="text" @click="handleEditBtn(slotRow.row)">编辑</el-button>-->
<!--                <el-button type="text" @click="handleDelBtn(slotRow.row)" style="color: #f56c6c">删除</el-button>-->
                <!--                <el-popover-->
                <!--                        v-if="userInfo.userType != 1"-->
                <!--                        placement="top"-->
                <!--                        width="120"-->
                <!--                        trigger="click"-->
                <!--                        v-model="slotRow.row.showPopup">-->
                <!--                    <p>确认删除</p>-->
                <!--                    <div style="text-align: right; margin: 0">-->
                <!--                        <el-button size="mini" type="text" @click="slotRow.row.showPopup = false">取消</el-button>-->
                <!--                        <el-button type="primary" size="mini" @click="handleDelBtn(slotRow.row)">确定</el-button>-->
                <!--                    </div>-->
                <!--                    <el-button slot="reference" type="text" style="color: #f56c6c">删除</el-button>-->
                <!--                </el-popover>-->
            </template>
        </recharge-products-table>


        <open-audit-dialog
                ref="openAuditDialogRef"></open-audit-dialog>


    </div>

</template>

<script type="text/ecmascript-6">
    import data from "./data"
    import rechargeProductsTable from "@/www/components/table/index"
    import openAuditDialog from "../dialog/index"
    import {
        rechargeProductInfoDelete
    } from "@/www/urls/rechargeProductsUrls"
    import {auditStatusList} from "../../../components/data"
    import {
        rechargeAuditInfoAudit
    } from "@/www/urls/openAuditUrls"

    export default {
        //定义模版数据
        data() {
            return {
                data,
                auditStatusList
            }
        },
        props: {
            serviceType: {
                type: String,
                default: '0'
            },  // 0 开通  1 审核
        },
        components: {
            rechargeProductsTable,
            openAuditDialog
        },
        //计算属性
        computed: {
            userInfo() {
                return this.$store.state.loginUserInfo
            },
            list() {
                return this.$store.state.openAudit.list
            },
        },
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            // handleEditBtn(item) {
            //     this.$emit("update:handleEditBtn", item);
            //     let form = {
            //         isShow: true,
            //         isAdd: 1,  // 0 新增  1 编辑
            //     };
            //     this.$store.dispatch('handle_dialog_info', form);
            // },
            async handleDelBtn(item) {
                try {
                    await this.$confirm('确定删除当前产品, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    const res = await rechargeProductInfoDelete(item.id);
                    let pageIndex = 1,
                        form = {};
                    if (res.success && res.data) {
                        this.$store.dispatch('search_recharge_product_list', {pageIndex, form});
                        this.loadingHide();
                        this.$message.success('删除成功！');
                    } else {
                        this.$message.error(res.errors[0].message)
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            handleSeeBtn(item){
                this.$router.replace('/vehicleServeSee/' + item.id);
            },  // 查看
            handleAuditBtn(item) {
                this.$router.replace('/vehicleServeAudit/' + item.id);
            },  // 审核
            handleEditBtn(item, type) {
                this.$router.replace('/vehicleServeEdit/' + item.id + '/' + type);
            },  // 修改
            async handleReSubBtn(item) {
                let form = {
                    id: item.id,
                    payStatus: 0
                };

                let res = await rechargeAuditInfoAudit(form);
                if (res.success && res.data) {
                    this.$message({
                        message: '重新提交成功',
                        type: 'success'
                    });
                    let form = {}
                    this.$store.dispatch('search_open_audit_list', {pageIndex: 1, form})
                }
                // this.$router.replace('/vehicleServeEdit/' + item.id + '/' +  type);
            },  // 重新提交
            handleLogBtn(item){
                let data = {
                    isShow: true,
                }
                this.$store.dispatch('handle_dialog_open_audit', data)
                this.$refs['openAuditDialogRef'].rechargeLogDtoList(item.id)

            },   // 日志

            async handleCancelBtn(item) {
                try {
                    await this.$confirm('我已确认取消车辆服务充值申请', '取消申请', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    console.log(item);
                    let form = {
                        id: item.id,
                        payStatus: 3
                    };
                    let res = await rechargeAuditInfoAudit(form);
                    if (res.success && res.data) {
                        this.$message({
                            message: '取消成功',
                            type: 'success'
                        });
                        let form = {}
                        this.$store.dispatch('search_open_audit_list', {pageIndex: 1, form})
                    }
                } catch (e) {
                    console.log(e);
                }
            }


        },
        //监听模版变量
        watch: {}

    }
</script>

