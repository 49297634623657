<style lang="scss" scoped>
.recharge-products-search /deep/ {
    .common-list-search {
        .list-search-before {
            width: calc(100% - 230px);

            .el-form-item.el-form-item--small {
                margin-bottom: 5px;
            }
        }

        .list-search-after {
            width: 230px;
        }
    }
}

</style>
<template>

    <div class="recharge-products-search">
        <com-list-search>
            <div slot="list-search-before">
                <el-form :inline="true" :model="form" size="small">
                    <el-form-item label="">
                        <el-input v-model="form.rechargeRecordNo" placeholder="编号ID" clearable></el-input>
                    </el-form-item>
                    <el-form-item style="width: 200px"
                                  v-if="userInfo.userType==='0'">
                        <operator-select
                            v-model="form.operatorId"
                            :is-edit="userInfo.userType != 0">
                        </operator-select>
                    </el-form-item>
                    <el-form-item style="width: 200px"
                                  v-if="userInfo.userType!=='1'">
                        <supplier-select
                            v-model="form.supplierId"
                            :operatorId="form.operatorId">
                        </supplier-select>
                    </el-form-item>
                    <el-form-item label="">
                        <et-select :options="rechargeProductTypes" placeholder="服务类型" v-model="form.productType"></et-select>
                    </el-form-item>
                    <el-form-item label="" style="width: auto">
                        <el-date-picker
                            style="width: 280px"
                            v-model="form.createTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="提交开始日期"
                            end-placeholder="提交结束日期"
                            value-format="yyyy/MM/dd 00:00:00"
                            @change="changeDate"
                            clearable>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="">
                        <el-input v-model="form.auditName" placeholder="提交人" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-select v-model="form.payStatus" placeholder="审核状态">
                            <el-option label="全部" value=""></el-option>
                            <el-option
                                v-for="item in auditStatusList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item label="">
                        <el-input v-model="form.transactionId" placeholder="支付流水号" clearable></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div slot="list-search-after" style="text-align: right;">
                <el-button type="primary" @click="exportBtn" v-if="serviceType === '1'">导出</el-button>
                <el-button @click="resetForm">重置</el-button>
                <el-button type="primary" @click="search(1)">搜索</el-button>
            </div>
        </com-list-search>
    </div>

</template>

<script type="text/ecmascript-6">
import comListSearch from '@/www/components/comListSearch/index.vue'
import operatorSelect from "@/www/components/operator-select/index"
import supplierSelect from "@/www/components/supplier-select/index"
import {initForm} from '@/www/vuex/modules/openAudit'
// import seatModel from "@/components/seatModel/index";
import {getSupplierIdOperatorInfo} from '@/urls/index';

import {combinationForm} from "@/common/js/common";
import {auditStatusList} from '../../../components/data'
import {
    rechargeAuditInfoListExport
} from "@/www/urls/openAuditUrls"
import {rechargeProductTypes} from "@/data";
import EtSelect from "@/components/et-select/index.vue";

export default {
    //定义模版数据
    data() {
        return {
            rechargeProductTypes,
            auditStatusList,
            form: {
                ...initForm
            },
        }
    },
    props: {
        serviceType: {
            type: String,
            default: '0'
        },  // 0 开通  1 审核
    },
    components: {
        EtSelect,
        comListSearch,
        operatorSelect,
        supplierSelect,
        // seatModel,
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        },
    },
    //主件被加载完成
    mounted: function () {


    },
    //定义事件方法
    methods: {
        searchRole(index) {
            const userType = parseInt(this.userInfo.userType);
            if (userType !== 0) {
                this.getUserInfo(userType, index);
            }
        },
        getUserInfo(type, index) {
            switch (type) {
                case 1:
                    this.getOperatorInfo(this.userInfo.refId);
                    break;
                case 2:
                    this.form.operatorId = this.userInfo.refId;
                    break;
            }
            this.search(index)
        },
        async getOperatorInfo(id) {
            const ret = await getSupplierIdOperatorInfo(id);
            if (ret) {
                this.form.supplierId = this.userInfo.refId;
                this.form.operatorId = ret.operatorId;
                this.form.operatorInfo = {
                    id: this.form.operatorId
                };
            }
        },
        search(pageIndex) {
            console.log(this.form);
            let form = JSON.parse(JSON.stringify({...this.form}));
            delete form.operatorInfo;
            delete form.supplierInfo;
            delete form.createTime;
            this.$store.dispatch('search_open_audit_list', {pageIndex, form})
        },

        handleOperator(item) {
            this.form.operatorInfo = item;
            this.form.operatorId = item.id;
        },
        clearOperator() {
            this.form.operatorInfo = {};
            this.form.operatorId = '';
        },
        handleSupplier(item) {
            this.form.supplierInfo = item;
            this.form.supplierId = item.id;
        },
        clearSupplier() {
            this.form.supplierInfo = {};
            this.form.supplierId = '';
        },

        resetForm() {
            const userType = parseInt(this.userInfo.userType);
            let form = {};
            switch (userType) {
                case 0:
                    // this.$refs.operatorInfoRef.clearOperatorName();
                    // this.$refs.supplierInfoRef.clearSupplierName();
                    form = {...initForm};
                    break;
                case 1:
                    form = {
                        operatorId: this.form.operatorId,
                        supplierId: this.form.supplierId,
                        carType: 0,
                        rechargeProductNo: ''
                    };
                    break;
                case 2:
                    form = {
                        operatorId: this.form.operatorId,
                        supplierId: '',
                        carType: 0,
                        rechargeProductNo: ''
                    };
                    // this.$refs.supplierInfoRef.clearSupplierName();
                    break;
            }
            this.form = {...form};
        },
        async exportBtn() {
            let form = JSON.parse(JSON.stringify({...combinationForm(this.form)}));
            delete form.createTime;
            try {
                const ret = await rechargeAuditInfoListExport(form);
                let d = new Date().getTime();
                let a = document.createElement('a');
                a.download = '服务审核列表' + d + '.xls';
                a.href = window.URL.createObjectURL(ret);
                a.click();
                this.loadingHide();

            } catch (e) {
                console.log(e);
                this.$message.error('导出失败')
                this.loadingHide();
            }
        },


        changeDate(date) {
            if (date) {
                this.form.createTimeStart = this.filterDate(date[0], 'yyyy-MM-dd');
                this.form.createTimeEnd = this.filterDate(date[1], 'yyyy-MM-dd');
            } else {
                delete this.form.createTimeStart;
                delete this.form.createTimeEnd
            }
        },

    },
    //监听模版变量
    watch: {}

}
</script>

