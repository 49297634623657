<style lang="scss" scoped>
    .order-contact-name /deep/ {
        display: flex;

        .el-form-item--small.el-form-item {
            margin-bottom: 0;
        }
    }
</style>
<template>

    <div>
        <bms-dialog :isShow="dialogInfo.isShow"
                    title="日志"
                    :width="420"
                    is-show-close
                    @update:closeBtn="handleCloseBtn"
        >
            <div slot="content">
                <ul style="margin-bottom: 15px" v-for="(item, index) in logInfo" :key="index">
                    <li>操作人：{{item.auditUserInfo}}</li>
                    <li>操作时间：{{item.auditTime | dateCus('yyyy-MM-dd hh:mm:ss')}}</li>
                    <li>操作内容：{{item.auditInfo}}</li>
                </ul>

            </div>
        </bms-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
    import bmsDialog from "@/www/components/dialog/index"
    import {
        rechargeLogDtoList
    } from "@/www/urls/openAuditUrls"

    export default {
        //定义模版数据
        data() {
            return {
                logInfo: []
            }
        },
        props: {
        },
        components: {
            bmsDialog,
        },
        //计算属性
        computed: {
            dialogInfo() {
                return this.$store.state.openAudit.dialogInfo;
            },
        },
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {

            handleCloseBtn(){
                let form = {
                    isShow: false,
                };
                this.$store.dispatch('handle_dialog_open_audit', form);
            },

            async rechargeLogDtoList(id){
                let form = {
                    auditId: id
                }
                let res = await rechargeLogDtoList(form)
                if(res.success && res.data){
                    this.logInfo = res.data
                }
            }

        },
        //监听模版变量
        watch: {}

    }
</script>

